<template>
  <div class="row">
    <div id="content" class="col-sm-12 merchstoreconfig">
      <div class="page-login">
        <div class="account-border">
          <div class="row" style="width: 100%;">
            <div class="col-sm-12 customer-login">
              <h2>Store Management</h2>
              <div class="well">
                
                <div class="form-group required">
                  <label class="control-label" for="input-name">Display Name / Store Name</label>
                  <input
                    type="text"
                    name="store_name"
                    v-model="storeObj.store_name"
                    class="form-control"
                    required
                    value
                  />
                </div>

                <div class="row">
                  <div class="col-sm-6 form-group required">
                    <label class="control-label" for="input-password">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="storeEmail"
                      :style="changeBorder"
                      @input="validateEmail(storeEmail)"
                    />
                  </div>
                  <div id="mobileNumcol" class="col-lg-4 col-md-6 col-sm-12">
                    <div class="input-group form-group required">
                      <label for="input-middlename" class="control-label">Mobile No</label>
                      <VuePhoneNumberInput
                        ref="mobilecom"
                        valid-color="green"
                        @input="validatePhone1(storeMobile)"
                        v-model="storeMobile"
                        default-country-code="LK"
                        :translations="{ phoneNumberLabel: '' }"
                        :only-countries="['LK']"
                        no-example
                        style="height:30px; cursor:auto;"
                        id="mobileInput"
                      />
                    </div>
                  </div>
                </div>
                <hr />

                <div>
                  <div v-for="(item, index) in addressArray" :key="index">
                    <h2 v-if="index === 0">Store Address (This will be your pick up location)</h2>
                    <h2 v-if="index === 1">Return Address</h2>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group required">
                          <label class="control-label">Country</label>
                          <select
                            class="form-control select-arrow"
                            @change="
                              changeCountries($event.target.value, index, true)
                            "
                            v-model="item.country"
                          >
                            <option value>--- Please Select ---</option>
                            <option
                              v-for="(item, val) in countries"
                              :key="val"
                              :value="item._id"
                              >{{ item.country_name }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group required">
                          <label class="control-label">Province</label>
                          <select
                            class="form-control select-arrow"
                            @change="
                              changeProvince($event.target.value, index, true)
                            "
                            v-model="item.province"
                          >
                            <option value>--- Please Select ---</option>
                            <option
                              v-for="(item, val) in provinceArray[index]"
                              :key="val"
                              :value="item._id"
                              >{{ item.province_name }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group required">
                          <label class="control-label">District / Area</label>
                          <select
                            class="form-control select-arrow"
                            @change="
                              changeRegion($event.target.value, index, true)
                            "
                            v-model="item.region"
                          >
                            <option value>--- Please Select ---</option>
                            <option
                              v-for="(item, val) in regionArray[index]"
                              :key="val"
                              :value="item._id"
                              >{{ item.region_name }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group required">
                          <label class="control-label">City</label>
                          <select
                            class="form-control select-arrow"
                            for="input-password"
                            @change="
                              changeCity($event.target.value, index, true)
                            "
                            v-model="item.city"
                          >
                            <option value>--- Please Select ---</option>
                            <option
                              v-for="(item, val) in cityArray[index]"
                              :key="val"
                              :value="item._id"
                              >{{ item.city_name }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label" for="input-password"
                          >Zone</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.zone"
                          disabled
                        />
                      </div>
                      <div class="col-sm-6">
                        <label class="control-label" for="input-password"
                          >Post Code</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.postal_code"
                          disabled
                        />
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group required">
                          <label class="control-label" for="input-password"
                            >Address Line 1</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Home Number"
                            v-model="item.address_line_1"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group required">
                          <label class="control-label" for="input-password"
                            >Address Line 2</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Lane"
                            v-model="item.address_line_2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <hr/> -->
                </div>

                <h2>Return Address</h2>
                  
                <div class="form-group col-sm-12">
                  <label class="control-label" >same as above</label> &nbsp; 
                  <input type="checkbox" checked= "checked" v-model="clickValue" @change="changeClick(clickValue)">
                </div>
                <br />
              </div>

              <!-- <hr/>
                    <h3>Holiday Mode</h3>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group required">
                        
                          <toggle-switch
                            :options="myOptions"
                            :disabled="false"
                            @change="updateMap(switchVal)"
                            v-model="switchVal"
                            :value="switchVal"
                            :name="name"
                            :group="switchGroup"
                          /> 

                          <p style="color: red">{{ notification }}</p>

                        </div>
                      </div>
                    </div> -->
<!-- 
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group required">
                        
                         <label>Holiday Starting Date</label>
                          
                           <datepicker
                            placeholder="Select Date"
                            :bootstrap-styling="true"
                            ref="refDatePick"
                            v-model="holiday_start_date"
                            :disabledDates="disabledDates"
                            @selected="setdisablEndDates()"
                          >
                            <span slot="afterDateInput" class="animated-placeholder"></span>
                          </datepicker>

                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group required">
                        
                         <label>Holiday Reopening Date</label>
                          
                           <datepicker
                            placeholder="Select Date"
                            :bootstrap-styling="true"
                            ref="refDatePickEnd"
                            v-model="holiday_end_date"
                            :disabledDates="disabledFromDates"
                          >
                            <span slot="afterDateInput" class="animated-placeholder"></span>
                          </datepicker>

                        </div>
                      </div>
                    </div> -->
              
              <div
                class="bottom-form"
                style="margin-bottom: 28px; margin-top: -59px;"
              >
                <a
                  type="submit"
                  value="Back"
                  @click="backToStore"
                  class="btn merchprof-sbtn pull-left"
                  >Back</a
                >
                <input
                  type="submit"
                  value="Submit"
                  :disabled="!formIsValid"
                  class="btn merchprof-sbtn pull-right"
                  @click="storeSubmit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinStore from "@/mixins/merchant/APIStore";
import mixinProfile from "@/mixins/merchant/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";
import Datepicker from "vuejs-datepicker";
import { async } from "q";
export default {
  mixins: [mixinCommon, mixinStore, mixinProfile],
  components: {
    VuePhoneNumberInput,
    Datepicker
  },
  data: function() {
    return {
      clickValue: true,
      isVisible: false,
      notification: "",
      storeObj: {},
      provinceArray: [],
      regionArray: [],
      storeEmail: "",
      storeMobile: "",
      holiday_start_date: new Date(),
      holiday_end_date: new Date(),
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() + 1) - 8640000)
      },
      disabledFromDates: {
        to: new Date(new Date().setDate(new Date().getDate() + 2) - 8640000)
      },
      changeBorder: {
        border: "1px solid #ccc",
      },
      cityArray: [],
      addressArray: [
        {
          address_line_1: "",
          address_line_2: "",
          country: "5d8b9e5dfb195816aaea61b3",
          province: "",
          region: "",
          city: "",
          postal_code: "",
          zone: ""
        },
      ],
      address: {
        address_line_1: "",
        address_line_2: "",
        country: "5d8b9e5dfb195816aaea61b3",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        zone: ""
      },
      countries: [],
      dissableVal: false,
      province: [],
      region: [],
      city: [],
      hmobileValid: false,
      holidayMode: [
        {
          id: 1,
          name: "OFF"
        },
        {
          id: 2,
          name: "ON"
        }
      ],
      breadcrumb: [
        {
          text: "Store Management",
          href: "/merchant-store",
          active: false,
        },
        {
          text: "Store Configure",
          href: "#",
          active: true,
        },
      ],
      switchVal: "Off",
      myOptions: {
        layout: {
          color: 'black',
          backgroundColor: 'lightgray',
          selectedColor: 'white',
          selectedBackgroundColor: 'green',
          borderColor: '#ccc',
          fontFamily: 'Arial',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 14,
          height: 4,
          padding: 0.7,
          width: 20
        },
        items: {
          delay: .4,
          preSelected: 'Off',
          disabled: false,
          labels: [
            {name: 'Off', color: 'white', backgroundColor: 'gray'}, 
            {name: 'On', color: 'white', backgroundColor: '#005baa'}
          ]
        }
      }
    };
  },
  computed: {
    sid() {
      let url = window.location.href.split("merchant-store-configure/");
      return url[1];
    },
    formIsValid() {
      return (
        this.addressArray[0].address_line_1 &&
        this.addressArray[0].address_line_2 &&
        this.addressArray[0].city &&
        this.addressArray[0].country &&
        this.addressArray[0].province &&
        this.addressArray[0].region &&
        !this.dissableVal
      );
    },
  },
  mounted() {
    this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);
  },
  watch: {
    holiday_start_date: function(val) {
      this.disabledDatesSet(val);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.getCountryDetails();
    this.getStoreByIdCheck();
    this.holiday_start_date.setDate(this.holiday_start_date.getDate() + 1);
    this.holiday_end_date.setDate(this.holiday_end_date.getDate() + 2);
  },
  methods: {
    backToStore() {
      this.$router.push("/merchant-store");
    },
    setdisablEndDates() {
      this.holiday_end_date = null;
      this.disabledDatesSet(this.holiday_start_date)
    },
    disabledDatesSet(val) {
      let toDate = val;
      if (this.sid !== "0") {
        this.disabledFromDates = {
            to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate() + 1) - 8640000)
        };
      } else {
        this.disabledFromDates = {
            to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate() + 1) - 8640000)
        };
        
      }
    },
    setMobileNo: function(val) {
      if (val.length <= 9) {
        this.storeObj.phone = val;
      } else {
        this.storeObj.phone = val.substring(0, val.length - 1);
      }
    },
    validateEmail(val) {
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.test(String(val))) {
        this.changeBorder.border = "1px solid green";
        this.dissableVal = false;
      } else {
        this.changeBorder.border = "1px solid red";
        this.dissableVal = true;
      }
      if (val === "") {
        this.changeBorder.border = "1px solid #ccc";
        this.dissableVal = true;
      }
    },
    validatePhone1(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
        this.dissableVal = false;
      }
      if (key.length < 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
        this.dissableVal = true;
      }

      if (this.storeMobile === "") {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.storeMobile = res;
      key = res;

      if (key === "0") {
        this.storeMobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.storeMobile = key;

      if (key.length == 9) {
        if (key == this.storeMobile.substring(3)) {
          this.hmobileValid = false;
          this.hmobileError = "Existing Phone Number Detected";
          this.$refs.mobilecom1.$children[1].$el.firstChild.style.borderColor =
            "red";
          this.dissableVal = true;
        } else {
          this.hmobileValid = true;
          this.dissableVal = false;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        this.dissableVal = true;
        this.hmobileError = "Enter valid phone number";
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    getCountryDetails: async function() {
      try {
        let response = await this.getCountry();
        this.countries = response;
        let id = "5d8b9e5dfb195816aaea61b3";
        this.changeCountries(id, 0, true)
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
        let response = await this.getProvince(event);
        this.province = response;

        this.$set(this.provinceArray, index, response);
        this.regionArray[index] = [];
        this.cityArray[index] = [];

        this.provinceArray[index] = response;

        // // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].province = "";
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
        let response = await this.getRegion(event);
        this.region = response;

        this.$set(this.regionArray, index, response);

        // RESETING
        this.cityArray[index] = [];
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
        let response = await this.getCity(event);
        this.city = response;

        this.$set(this.cityArray, index, response);

        // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeCity: function(event, index, useraction = false) {
      let cityObj = this.city.filter(el => {
        return el._id == event;
      });

      this.addressArray[index].zone = cityObj[0].zone;
      this.addressArray[index].postal_code = cityObj[0].postal_code;
    },
    changeClick: async function(val) {
      if (val === false) {
        this.clickValue = false;
        this.isVisible = true;
        if (this.addressArray.length < 2) {
          this.addressArray.push(this.address);
        }
      } else {
        if (this.addressArray.length >= 2) {
          // if (this.addressArray[1]._id) {
          //   await this.deleteStoreAddressById(this.addressArray[1]._id);
          // }
          this.addressArray.splice(1, 1);
        }
        this.isVisible = false;
      }
    },
    storeSubmit: async function() {
      this.storeObj.addresses = this.addressArray;

      for (let index = 0; index < this.addressArray.length; index++) {
        if (
          this.addressArray[index].address_line_1 === "" ||
          this.addressArray[index].address_line_2 === "" ||
          this.addressArray[index].city === "" ||
          this.addressArray[index].country === "" ||
          this.addressArray[index].province === "" ||
          this.addressArray[index].region === ""
        ) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Complete Address Information!",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
      }

      this.storeObj.email =  this.storeEmail;
      this.storeObj.phone = this.storeMobile;
      
      this.storeObj.holiday_start_date = moment(this.holiday_start_date).format("YYYY-MM-DD");
      this.storeObj.holiday_end_date = moment(this.holiday_end_date).format("YYYY-MM-DD");
      try {

        if (this.storeObj._id) {
          await this.putStore(this.storeObj);
        } else {
          await this.postStore(this.storeObj);
          this.postRequiredStage(6);
        }
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit success",
          showConfirmButton: false,
          timer: 1500,
        });

        let isUpdate = true
        this.$store.commit("setActiveStore", {
          action: isUpdate,
          payload: null
        });

        this.$router.push("/merchant-store");
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    postRequiredStage: async function(stage) {
      let obj = {
        complated_status: stage,
      };

      let response = await this.postDashboardStage(obj);

    },
    DeleteClick: async function(itemid) {
      this.addressArray.splice(1, 1);
      this.addressArray[0].is_biling_address = true;
      if (itemid) {
        await this.deleteStoreAddressById(itemid);
      }
    },
    getStoreByIdCheck: async function() {
      if (this.sid == "0") {
        let responseprofile = await this.getProfileBacicInfo();
        this.storeEmail = responseprofile.email
        this.storeMobile = responseprofile.mobile
        this.storeObj.active_status = responseprofile.active_status


        if (this.storeObj.active_status == 1) {
          this.switchVal = "Off";
        }
        if (this.storeObj.active_status == 2) {
          this.switchVal = "On";
        }
        // this.storeObj.email = responseprofile.email;
        // this.storeObj.phone = responseprofile.mobile;
        // if (responseprofile.addresses.length > 0) {
        //   await this.changeCountries(responseprofile.addresses[0].country, 0);
        //   await this.changeProvince(responseprofile.addresses[0].province, 0);
        //   await this.changeRegion(responseprofile.addresses[0].region, 0);
        //   responseprofile.addresses[0].address =
        //     responseprofile.addresses[0].address_line_1;
        //   this.addressArray = [responseprofile.addresses[0]];
        // }
      }
      if (this.sid !== "0") {
        try {
          let response = await this.getStoreById(this.sid);
          this.storeObj = response;
          this.storeEmail = response.email;
          this.storeMobile = response.phone;

          if (this.storeObj.active_status == 1) {
            this.switchVal = "Off";
          }
          if (this.storeObj.active_status == 2) {
            this.switchVal = "On";
          }
          this.holiday_start_date = response.holiday_start_date;
          this.holiday_end_date = response.holiday_end_date;

          if (response.holiday_start_date == null || response.holiday_start_date == undefined) {
            this.holiday_start_date = new Date();
            this.holiday_start_date.setDate(this.holiday_start_date.getDate() + 1);
          }

          if (this.holiday_end_date == null || this.storeObj.holiday_end_date == undefined) {
            this.holiday_end_date = new Date();
            this.holiday_end_date.setDate(this.holiday_end_date.getDate() + 1);
          }


          for (let index = 0; index < response.addresses.length; index++) {
            await this.changeCountries(
              response.addresses[index].country,
              index
            );
            await this.changeProvince(
              response.addresses[index].province,
              index
            );
            await this.changeRegion(response.addresses[index].region, index);
            this.addressArray = response.addresses;
          }
          this.addressArray = response.addresses;
          // console.log(this.addressArray)

          if (this.addressArray.length > 1) {
            this.clickValue = false;
          } else {
            this.clickValue = true;
          }

          if (this.addressArray.length === 0) {
            this.clickValue = true;
            this.changeClick(true);
            this.addressArray = [
              {
                address: "",
                country: "5d8b9e5dfb195816aaea61b3",
                province: "",
                region: "",
                city: "",
                zone: "",
                postal_code: "",
              },
            ];
          }

          this.addressArray[0].zone = response.zone;

        } catch (error) {
          console.log(error);
        }
      }
    },
    updateMap: function(val){
      if (val === "On") {
        this.storeObj.active_status = 2
        this.notification = "Enable this feature to hide your products from the Cochchi site temporarily. Once you disable this, all your products will go back to being displayed Cochchi.lk";
      }
      if (val === "Off") {
        this.storeObj.active_status = 1
        this.notification = "";
      }
    },
    
  },
};
</script>
<style scoped>
.merchstoreconfig .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchstoreconfig .merchprof-sbtn:hover {
  color: #000000;
}
</style>
